import axios from "axios";

axios.defaults.baseURL = 'https://service.gbitex.com/api';
//axios.defaults.baseURL = 'https://localhost:7172/api';
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;

axios.interceptors.response.use((response) => response, (error) => {

    if (error.code=="ERR_NETWORK") {
        localStorage.clear();
        window.location.reload();
    }
});
