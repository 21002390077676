import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './utils/axios.js'
// Import the Toast or use your own!
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";


import Vue3PersianDatetimePicker from 'vue3-persian-datetime-picker';


let app = createApp(App);
app.component('DatePicker', Vue3PersianDatetimePicker);
app.use(Toast);
app.use(router);
app.mount('#app');