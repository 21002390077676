<template>
    <aside id="layout-menu" class="layout-menu menu-vertical menu bg-menu-theme">
        <div class="app-brand demo">
            <a href="index.html" class="app-brand-link">
                <span class="app-brand-log bg-dark rounded-circle">
                 <img src="../../../public/img/logo.png" />
                </span>
            </a>
        </div>

        <div class="menu-inner-shadow"></div>

        <ul class="menu-inner py-1">
            <!-- Page -->
            <li class="menu-item" @click="closeSidebar()">
                <router-link to="/" class="menu-link">
                    <i class="menu-icon tf-icons ti ti-home"></i>
                    <div >خانه</div>
                </router-link>
            </li>
            <li class="menu-item" @click="closeSidebar()">
                <router-link to="/User" class="menu-link">
                    <i class="menu-icon tf-icons ti ti-users"></i>
                    <div >کاربران</div>
                </router-link>
            </li>
            <li class="menu-item" @click="closeSidebar()">
                <router-link to="/Transactions" class="menu-link">
                    <i class="menu-icon tf-icons ti ti-currency-dollar"></i>
                    <div>تراکنش ها</div>
                </router-link>
            </li>
            <li class="menu-item" @click="closeSidebar()">
                <router-link to="/BankCards" class="menu-link">
                    <i class="menu-icon tf-icons ti ti-credit-card"></i>
                    <div >کارت های بانکی</div>
                </router-link>
            </li>
            <li class="menu-item" @click="closeSidebar()">
                <router-link to="/Order" class="menu-link">
                    <i class="menu-icon tf-icons ti ti-basket"></i>
                    <div>سفارشات</div>
                </router-link>
            </li>
            <li class="menu-item" @click="closeSidebar()">
                <router-link to="/Config" class="menu-link">
                    <i class="menu-icon tf-icons ti ti-percentage"></i>
                    <div>درصد ها و قیمت ها</div>
                </router-link>
            </li>
            <li class="menu-item" @click="closeSidebar()">
                <router-link to="/MoneyRequest" class="menu-link">
                    <i class="menu-icon tf-icons ti ti-report-money"></i>
                    <div >درخواست برداشت وجه</div>
                </router-link>
            </li>
            <li class="menu-item" @click="closeSidebar()">
                <router-link to="/CryptoRequest" class="menu-link">
                    <i class="menu-icon tf-icons ti ti-coin"></i>
                    <div >درخواست برداشت ارز</div>
                </router-link>
            </li>
        </ul>
    </aside>
</template>
<script>
export default{
    methods:{
        closeSidebar(){
            var element = document.getElementById('layout-menu');
            element.classList.remove("open-menu");
            
        }
    }
}

</script>

<style>
.menu-vertical{
    transition: 500ms all !important;
}
</style>